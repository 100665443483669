import { createContext, FC, useMemo } from "react";
import { initializeApp, FirebaseApp, getApps, getApp } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export interface IFirebaseContext {
  firebase: FirebaseApp;
}

export const FirebaseContext = createContext<IFirebaseContext>({
  firebase: getApps()[0],
});

export interface IFirebaseProviderProps {
  children: any;
}

export const FirebaseProvider: FC<IFirebaseProviderProps> = ({ children }) => {
  const app = useMemo(() => {
    if (getApps().length === 0) {
      return initializeApp(firebaseConfig);
    }
    return getApp();
  }, []);

  return <FirebaseContext.Provider value={{ firebase: app }}>{children}</FirebaseContext.Provider>;
};

import { Card } from "react-bootstrap";
import SVG from "react-inlinesvg";

import oocaLogo from "@/assets/svg/ooca_logo-default-side.svg";

export interface OocaCardProps {
  children: React.ReactNode;
  title?: string;
  subTitle?: string;
}

export const OocaCard: React.FC<OocaCardProps> = (props) => {
  return (
    <Card className="bg-blue-50 login-container border-0">
      <SVG src={oocaLogo} className="ooca-logo"></SVG>

      <div className="mt-4">
        <Card.Title className="text-title1 mb-2 text-center">{props.title}</Card.Title>
        <Card.Subtitle className="text-subheader2 text-blue-gray-1 mt-3">
          {props.subTitle}
        </Card.Subtitle>
      </div>

      {props.children}
    </Card>
  );
};

import React from "react";
import SVG from "react-inlinesvg";
import oocaLogo from "@/assets/svg/ooca_logo-default-side.svg";
import "@/components/loading/fullLoader.scss";
import { Row } from "react-bootstrap";

export interface FullLoadingProps {
  isShowModal: boolean;
  fullTitle?: string;
  fullBodyText?: string;
}
const FullLoader: React.FC<FullLoadingProps> = ({
  isShowModal,
  fullTitle,
  fullBodyText,
}) => {
  return (
    <>
      <div className={isShowModal ? "" : "d-none"}>
        <div className="fullscreen-loading m-auto">
          <div className="background"></div>
          <Row className="mt-5">
            <SVG src={oocaLogo} />
          </Row>
          <div className="container-card">
            <h1 className="sr-only text-blue-1 my-3">{fullTitle}</h1>
            <div className="text-body1">{fullBodyText}</div>
            <div className="lds-spinner mt-2">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FullLoader;

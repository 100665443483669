import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import "@/localization/config";
import Toast from "@/components/toast/toast";
import Routes from "@/routes";

import "@/scss/main.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FirebaseProvider } from "@/utils/firebase";
import { LoaderProvider } from "@/contexts/loaderContext";

function App() {
  const { i18n } = useTranslation();
  const [language] = useState(localStorage.getItem("language") ?? "th");
  const isStaging = process.env.REACT_APP_IS_STAGING;

  useEffect(() => {
    i18n.changeLanguage(language);
    localStorage.setItem("language", language);
  }, [language, i18n]);

  useEffect(() => {
    let link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    if (isStaging === "true") {
      link.href = process.env.PUBLIC_URL + "/favicon_staging.png";
    }
  }, [isStaging]);

  // use on change Language
  // const onChangedLanguage = (lang: string) => {
  //   setLanguage(lang);
  // }

  // const getSelectedLanguage = () => {
  //   switch (language) {
  //     case 'en':
  //         return languagesEnum.ENGLISH;
  //     default:
  //         return languagesEnum.THAI;
  //   }
  // }

  return (
    <FirebaseProvider>
      <div>
        <LoaderProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{"OOCA Admin Corp"}</title>
          </Helmet>
          {/* We use BrowserRouter specifically because Router is on a higher-level and it will implicitly use one the implementations of Router by default anyway */}

          <BrowserRouter>
            <div className="app">
              <Routes />
            </div>
          </BrowserRouter>
          <Toast />
        </LoaderProvider>
      </div>
    </FirebaseProvider>
  );
}

export default App;

import { Col, Container, Row } from "react-bootstrap";
import "@/pages/errorPage/pageNotFound.scss";
import SVG from "react-inlinesvg";

import sadMooca from "@/assets/svg/sad-mooca-size-m.svg";
import oocaLogo from "@/assets/svg/ooca_logo-default-side.svg";
import { useTranslation } from "react-i18next";
import { FC, ReactNode } from "react";

type PageNotFoundProps = {
  errorCode?: number;
  subTitle?: string;
  children?: ReactNode;
};

const PageNotFound: FC<PageNotFoundProps> = ({
  errorCode = 404,
  subTitle = "SORRY_PAGE_NOT_FOUND",
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div className="errorPage">
      <Row className="text-center mt-5">
        <SVG src={oocaLogo} className="ooca-logo"></SVG>
      </Row>

      <Container className="errorPage__content">
        <Row className="text-center">
          <Col md={12}>
            <SVG src={sadMooca}></SVG>
          </Col>

          <Col md={12}>
            <h1 className="title">{errorCode}</h1>
            <h1 className="sub-title">{t(subTitle)}</h1>
            <h3 className="description">
              {children ?? (
                <>
                  {t("PLEASE_TRY_AGAIN_OR")} <br /> {t("CONTACT_OOCA_TEAM")}
                </>
              )}
            </h3>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PageNotFound;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { OocaCard } from "@/components/oocaCard/oocaCard";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { clientAuth } from "@/utils/axios";
import { useState } from "react";
import { toast } from "react-toastify";
import ConfirmModal from "@/components/confirmModal/confirmModal";
import "@/components/login/loginForm.scss";
import "@/pages/forgotPassword/forgotPassword.modules.scss";
import SVG from "react-inlinesvg";
import moocaIcon from "@/assets/svg/mooca-logo.svg";

import viewOn from "@/assets/svg/view-on-bold.svg";
import viewOff from "@/assets/svg/view-off-bold.svg";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

interface FormInterface {
  email: string;
  otp: string;
}

interface OtpValidationProps {
  email: string;
  isShow: boolean;
}

const OtpValidationForm: React.FC<OtpValidationProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [showOtpText, setShowOtpText] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailText, setEmailText] = useState<string>("");

  let schema = yup.object().shape({
    email: yup.string().email().required(),
    otp: yup.string().required("OTP is required"),
  });

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm<FormInterface>({
    resolver: yupResolver(schema),
    defaultValues: { email: props.email },
  });

  const resendEmail = async () => {
    const { email } = await getValues();

    const body = JSON.stringify({
      email: email,
      url: `${process.env.REACT_APP_OOCA_BUSINESS}/reset-password`,
    });

    if (!_.isEmpty(email)) {
      clientAuth()
        .post("/authen/email-links", body)
        .then((res) => {
          setIsShowModal(true);
          setEmailText(email);
        })
        .catch((err) => {
          if (err.response.status === 500) {
          } else {
            toast.error(err.message);
          }
        });
    }
  };

  const onValidateOtp = async () => {
    const { email, otp } = await getValues();
    setIsLoading(true);

    const body = JSON.stringify({
      email: email,
      password: otp,
    });

    clientAuth()
      .post("/authen/tokens", body)
      .then((res) => {
        setIsLoading(false);
        localStorage.setItem("firebase_auth_token", res.data.data.firebase_auth_token);
        navigate("/reset-password");
      })
      .catch((err) => {
        if (err) {
          setIsLoading(false);
          setError("otp", {
            type: "invalid",
            message: "Invalid OTP code",
          });
        }
      });
  };

  const toggleShow = () => {
    setIsShowModal(!isShowModal);
  };

  return (
    <>
      {props.isShow && (
        <>
          <div className="page-center">
            <OocaCard
              title={t("LOGIN_FORGOT_PASSWORD")}
              subTitle={t("LOGIN_FORGOT_PASSWORD_SUBTITLE")}
            >
              <Form className="input-login-form mt-4">
                <Form.Group className="px-2 group-email">
                  <Form.Label className="text-body2 mb-2 text-blue-gray-700 ">
                    {t("LOGIN_EMAIL")}
                  </Form.Label>
                  <Form.Control
                    className="text-body3 ooca-text-box "
                    type="email"
                    placeholder="Email"
                    {...register("email", { required: true })}
                    isInvalid={!!errors.email}
                  />
                </Form.Group>

                <Form.Group className="px-2 group-password">
                  <Form.Label className="text-body2 mb-2 text-blue-gray-700">{t("OTP")}</Form.Label>

                  <InputGroup>
                    <div className="ooca-text-box mx-auto">
                      <Form.Control
                        className="text-body3 ooca-text-box"
                        type={showOtpText ? "text" : "password"}
                        placeholder="OTP code"
                        {...register("otp")}
                        isInvalid={!!errors.otp}
                      />
                      <InputGroup.Text
                        id="eye-view-password"
                        onClick={() => setShowOtpText(!showOtpText)}
                      >
                        <SVG src={showOtpText ? viewOn : viewOff}></SVG>
                      </InputGroup.Text>
                      {errors.otp && (
                        <Form.Control.Feedback type="invalid">
                          {errors.otp.message}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </InputGroup>
                  <div className="mt-5 resend-box text-blue-gray-2 text-body4">
                    <p>{t("HAVEN_RECEIVED_OTP")}</p>
                    <div
                      className="resend-link text-button-underline"
                      onClick={resendEmail}
                      style={{ cursor: "pointer" }}
                    >
                      {t("RESEND_VERIFICATION_LINK")}
                    </div>
                  </div>
                </Form.Group>

                <Button
                  className="btn-login text-button-small"
                  variant="primary"
                  onClick={handleSubmit(onValidateOtp)}
                  disabled={isLoading}
                >
                  {t("SUBMIT")}
                </Button>
              </Form>
            </OocaCard>
          </div>

          <ConfirmModal
            isConfirm={false}
            isShow={isShowModal}
            setIsShow={toggleShow}
            setHide={toggleShow}
            title={"Verify email"}
            icon={<SVG src={moocaIcon}></SVG>}
          >
            <>
              <Row>
                <div>
                  {t("SENT_EMAIL_TEXT")}{" "}
                  <span className="text-blue-gray-1 fw-bold">{emailText}.&nbsp;</span>
                </div>
              </Row>

              <Row>
                <div>{t("ACCESS_THE_LINK_IN_EMAIL")}</div>
              </Row>
            </>
          </ConfirmModal>
        </>
      )}
    </>
  );
};

export default OtpValidationForm;

import { FC, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Nav, NavDropdown } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ISidebarItem } from "@/constants/sidebarItem";

import KeyboardArrowUpIcon from "@/assets/svg/control-close-small.svg";
import KeyboardArrowDownIcon from "@/assets/svg/control-open-small.svg";

import SvgIcon from "@mui/material/SvgIcon";

const ItemLink: FC<ISidebarItem> = ({ path, text, group, icon, iconSelected }): JSX.Element => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const itemKey = path + text;
  const { t } = useTranslation();

  const isSelected = pathname === path; // TODO => Fix this

  const renderGroupArrowIcon = function (group: ISidebarItem[], show: boolean) {
    if (group.length > 0) {
      return show ? (
        <SVG className="side-group__arrow" src={KeyboardArrowUpIcon} />
      ) : (
        <SVG className="side-group__arrow" src={KeyboardArrowDownIcon} />
      );
    }
  };

  const renderIcon = function (icon?: any, className?: string): JSX.Element {
    if (icon) {
      if (
        icon.type !== undefined &&
        icon.type.render !== undefined &&
        icon.type.render.muiName !== undefined
      ) {
        // Material UI icons
        return <SvgIcon component={icon} />;
      } else if (typeof icon == "string") {
        // Custom icons with source passed as string.
        return <SVG className={className} src={icon} />;
      } else {
        if (typeof icon === "function") {
          // React-icons >> https://react-icons.github.io/react-icons <<
          return icon();
        }

        // Already a JSX Element
        return icon;
      }
    }

    // Not specified - no icon -
    return <div />;
  };

  useEffect(() => {
    if (isSelected) {
      document.title = t(text);
    }
  }, [isSelected, text, t]);

  const linkAction = (path: string | undefined) => {
    // TODO : check permission before push
    if (path) {
      document.title = t(text);
      navigate(path as string);
    }
  };
  const checkIsChildSelected = () => {
    return group?.some((g) => g.path === pathname);
  };

  const getClassNameDropdown = (isChildSelected: boolean, selected: boolean) => {
    let className: string = "overlay-nav-item";
    if (isChildSelected) {
      className += " group-selected";
    }
    if (selected) {
      className += " selected";
    }
    return className;
  };

  const sideMenuGroup = () => {
    if (group?.length > 0) {
      return (
        <NavDropdown
          title={
            <div className={"sidebar-content"}>
              {isSelected || checkIsChildSelected()
                ? renderIcon(iconSelected, "item-svg")
                : renderIcon(icon, "item-svg")}
            </div>
          }
          key={itemKey + "_menu"}
          className={getClassNameDropdown(checkIsChildSelected(), false)}
          onClick={() => setShow(!show)}
        >
          {group.map((item, index) => {
            const selecedChild = item.path === pathname;
            return (
              <NavDropdown.Item
                key={itemKey + "_menu_" + index}
                className={getClassNameDropdown(false, selecedChild)}
              >
                <Nav.Link
                  eventKey={itemKey}
                  className={"sidebar-content side-group__item menu-item ml-0"}
                  onClick={() => linkAction(item.path)}
                >
                  {isSelected
                    ? renderIcon(item.iconSelected, "item-svg")
                    : renderIcon(item.icon, "item-svg")}
                  <span className="text-subheader1">{t(item.text)}</span>
                </Nav.Link>
              </NavDropdown.Item>
            );
          })}
        </NavDropdown>
      );
    }

    return <></>;
  };

  const getClassNameItem = (isChildSelected: boolean, selected: boolean, isGroup: boolean) => {
    let className: string = "nav-main-item";
    if (isChildSelected) {
      className += " group-selected";
    }
    if (selected) {
      className += " selected";
    }
    if (isGroup) {
      className += " main-item";
    }
    return className;
  };

  return (
    <>
      <Nav.Item
        key={itemKey}
        className={getClassNameItem(checkIsChildSelected(), isSelected, group?.length > 0)}
        onClick={() => setShow(!show)}
      >
        <Nav.Link eventKey={itemKey} className={"sidebar-content"} onClick={() => linkAction(path)}>
          {isSelected || checkIsChildSelected()
            ? renderIcon(iconSelected, "item-svg")
            : renderIcon(icon, "item-svg")}
          <span className="text-subheader1">{t(text)}</span>
        </Nav.Link>
        {renderGroupArrowIcon(group, show)}
      </Nav.Item>
      {show &&
        group?.length > 0 &&
        group.map((item, index) => {
          const selecedChild = item.path === pathname;
          return (
            <Nav.Item
              key={itemKey + "_" + index}
              className={getClassNameItem(false, selecedChild, true)}
            >
              <Nav.Link
                eventKey={itemKey}
                className={"sidebar-content side-group__item"}
                onClick={() => linkAction(item.path)}
              >
                {isSelected
                  ? renderIcon(item.iconSelected, "item-svg")
                  : renderIcon(item.icon, "item-svg")}
                <span className="text-subheader1">{t(item.text)}</span>
              </Nav.Link>
            </Nav.Item>
          );
        })}
      {sideMenuGroup()}
    </>
  );
};

export default ItemLink;

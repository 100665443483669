import { PATH } from "@/constants/path";
import { getUserToken } from "@/utils/authUtil";
import _ from "lodash";
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export interface ProtectedRouteInterface {
  redirectPath?: string;
  children?: any;
  roleCheck: string[];
}

const ProtectedRoute = ({
  redirectPath = PATH.LOGIN,
  children,
  roleCheck,
}: ProtectedRouteInterface) => {
  const user = getUserToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (!_.isNull(user) && !_.isUndefined(user)) {
      let isAllow: boolean = false;

      _.each(roleCheck, (role) => {
        const findRole = user.data.roles.find((check) => role === check);

        if (findRole) {
          isAllow = true;
        }
      });

      if (!isAllow) navigate(redirectPath);
    }
  }, [roleCheck, redirectPath, user, navigate]);

  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;

import { createContext, FC, useMemo, useState } from "react";
import Loading from "@/components/loading/loader";
import FullLoader from "@/components/loading/fullLoader";

export interface LoaderTextInterface {
  title: string;
  bodyText?: string;
}

export const LoaderContext = createContext({
  isShow: false,
  showLoading: (loaderText?: LoaderTextInterface) => {},
  closeLoading: () => {},
  showFullLoading: (fullLoaderText?: LoaderTextInterface) => {},
  closeFullLoading: () => {},
});

export interface ILoaderProviderProps {
  children: any;
}

export const LoaderProvider: FC<ILoaderProviderProps> = ({ children }) => {
  const [isShow, setIsShow] = useState(false);
  const [isShowFull, setIsShowFull] = useState(false);
  const [loaderText, setLoaderText] = useState<LoaderTextInterface>({
    title: "",
    bodyText: "",
  });

  const showLoading = (loaderText: LoaderTextInterface | undefined) => {
    if (loaderText) {
      setLoaderText(loaderText);
    }
    setIsShow(true);
  };
  const closeLoading = () => {
    setIsShow(false);
  };

  const showFullLoading = (fullLoaderText: LoaderTextInterface | undefined) => {
    if (fullLoaderText) {
      setLoaderText(fullLoaderText);
    }
    setIsShowFull(true);
  };

  const closeFullLoading = () => {
    setIsShowFull(false);
  };

  const value = useMemo(
    () => ({
      isShow,
      showLoading,
      closeLoading,
      showFullLoading,
      closeFullLoading,
    }),
    [isShow]
  );

  return (
    <LoaderContext.Provider value={value}>
      {children}
      <Loading
        show={isShow}
        title={loaderText.title}
        bodyText={loaderText.bodyText}
      ></Loading>
      <FullLoader
        isShowModal={isShowFull}
        fullTitle={loaderText.title}
      ></FullLoader>
    </LoaderContext.Provider>
  );
};

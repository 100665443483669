import { FC } from "react";
import SVG from "react-inlinesvg";
import avatarBlue from "@/assets/svg/avatar-blue.svg";

import "./navbar.scss";

export interface NavbarProps {
  pageName: string;
  userName?: string; //TODO: change to user data
  userPicture?: string; //TODO: change to user data
}

const Navbar: FC<NavbarProps> = (props: NavbarProps) => {
  return (
    <div className="navbar">
      <div className="container">
        <div className="container__left">
          <span className="text-h2 m-0">{props.pageName}</span>
        </div>

        <div className="container__right">
          <span className="text-subheader1 text-blue-gray-2">
            {props.userName}
          </span>
          <SVG className="icon__user" src={avatarBlue} />
        </div>
      </div>
    </div>
  );
};

export default Navbar;

import { SvgIconTypeMap } from "@mui/material/SvgIcon";
import { PATH } from "./path";

// **** Just copy import from this repository in the link below. ****
// https://mui.com/material-ui/material-icons/
// ******************************************************************
import { OverridableComponent } from "@mui/material/OverridableComponent";

// We can also add custom SVG here...
//import burgerMenu from "../assets/svg/hamburger_menu.svg";
import { IconType } from "react-icons";

import homeRegular from "@/assets/svg/home-regular.svg";
import homeBold from "@/assets/svg/home-bold.svg";
import reportRegular from "@/assets/svg/graph1-regular.svg";
import reportBold from "@/assets/svg/graph1-bold.svg";
import userRegular from "@/assets/svg/user1-regular.svg";
import userBold from "@/assets/svg/user1-bold.svg";
import settingRegular from "@/assets/svg/setting-regular.svg";
import settingBold from "@/assets/svg/setting-bold.svg";
import listRegular from "@/assets/svg/list-regular.svg";
import listBold from "@/assets/svg/list-bold.svg";
import lineRegular from "@/assets/svg/x-axis-grid-regular.svg";
import lineBold from "@/assets/svg/x-axis-grid-bold.svg";

export interface ISidebarItem {
  path?: string;
  text: string;
  icon?: string | IconType | OverridableComponent<SvgIconTypeMap>;
  iconSelected?: string | IconType | OverridableComponent<SvgIconTypeMap>;
  isgroup: boolean;
  group: ISidebarItem[];
}

export const SIDEBAR_ITEMLIST: ISidebarItem[] = [
  {
    path: PATH.OVERVIEW,
    text: "MENU_OVERVIEW",
    icon: homeRegular,
    iconSelected: homeBold,
    isgroup: false,
    group: [],
  },
  {
    text: "MENU_REPORT",
    icon: reportRegular,
    iconSelected: reportBold,
    isgroup: true,
    group: [
      {
        path: PATH.COUNSELING,
        text: "MENU_COUNSELING",
        icon: lineRegular,
        iconSelected: lineBold,
        isgroup: false,
        group: [],
      },
      {
        path: PATH.TEST,
        text: "MENU_TEST",
        icon: lineRegular,
        iconSelected: lineBold,
        isgroup: false,
        group: [],
      },
      
    ],
  },
  {
    path: PATH.MANAGEMENT,
    text: "MENU_MANAGEMENT",
    icon: userRegular,
    iconSelected: userBold,
    isgroup: false,
    group: [],
  },
  {
    path: PATH.SETTING,
    text: "MENU_SETTING",
    icon: settingRegular,
    iconSelected: settingBold,
    isgroup: false,
    group: [],
  },
  {
    path: PATH.CORPORATE_LIST,
    text: "MENU_CORPORATE_LIST",
    icon: listRegular,
    iconSelected: listBold,
    isgroup: false,
    group: [],
  },
];

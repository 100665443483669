import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { removeToken, saveRefreshToken, saveUserToken } from "./authUtil";
import { PATH } from "@/constants/path";

interface TokenInterface {
  data: {
    token: string;
    refresh_token: string;
  };
}

axios.defaults.headers.post["Content-Type"] = "application/json";

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (
        error.config &&
        error.response.status === 400 && // Use the status code your server returns when token has expired
        !error.config._retry
      ) {
        console.log("Access token 401");
        error.config._retry = true;
        try {
          const refreshTokenResult = await refreshToken(error.config);
          const { token } = refreshTokenResult.data.data;
          error.config.headers.Authorization = `Bearer ${token}`;
          return instance().request(error.config); // Repeat the initial request
        } catch (error) {
          console.log("Refresh Token error => to login page");
          removeToken();
          window.location.href = PATH.LOGIN;
          return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    }
  );
  return axiosInstance;
};

const instance = (auth: boolean = true) => {
  const localStorageToken = window.localStorage.getItem("token");
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10 * 60 * 1000,
    withCredentials: false,
    headers: {
      "Content-type": "application/json",
      Authorization:
        auth && localStorageToken ? `Bearer ${localStorageToken}` : "",
    },
  });
};

const refreshToken = (config: any) => {
  return new Promise<AxiosResponse<TokenInterface, any>>((resolve, reject) => {
    const body = JSON.stringify({
      token: localStorage.getItem("refresh_token") ?? null,
    });

    console.log("Call Refresh Token");
    instance()
      .post<TokenInterface>(
        `${process.env.REACT_APP_API_AUTHEN}/authens/refresh-tokens`,
        body
      )
      .then((res) => {
        saveUserToken(res.data.data.token);
        saveRefreshToken(res.data.data.refresh_token);
        return resolve(res);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

const clientInstance = (auth: boolean = true) => {
  const localStorageToken = window.localStorage.getItem("token");
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10 * 60 * 1000,
    withCredentials: false,
    headers: {
      "accept-language": "en",
      Authorization:
        auth && localStorageToken ? `Bearer ${localStorageToken}` : "",
    },
  });
};

const client = (auth: boolean = true) =>
  setupInterceptorsTo(clientInstance(auth));

export default client;

const clientAuth = (auth: boolean = false) => {
  const localStorageToken = window.localStorage.getItem("token");
  return axios.create({
    baseURL: process.env.REACT_APP_API_AUTHEN,
    timeout: 10 * 60 * 1000,
    withCredentials: false,
    headers: {
      "accept-language": "en",
      Authorization: localStorageToken ? `Bearer ${localStorageToken}` : "",
    },
  });
}

const clientAdminInstance = (auth: boolean = true) => {
  const localStorageToken = window.localStorage.getItem("token");
  return axios.create({
    baseURL: process.env.REACT_APP_API_ADMIN,
    timeout: 10 * 60 * 1000,
    withCredentials: false,
    headers: {
      "accept-language": "en",
      Authorization:
        auth && localStorageToken ? `Bearer ${localStorageToken}` : "",
    },
  });
};

const clientAdmin = (auth: boolean = true) =>
  setupInterceptorsTo(clientAdminInstance(auth));

export { clientAdmin, clientAuth };

import { PATH } from "@/constants/path";
import PageNotFound from "@/pages/errorPage/pageNotFound";
import { useTranslation } from "react-i18next";

const LinkExpiredPage = () => {
  const { t } = useTranslation();

  return (
    <PageNotFound errorCode={404} subTitle={t("LINK_EXPIRED_ERROR")}>
      <>
        <a href={PATH.FORGOT_PASSWORD}>{t("PLEASE_TRY_RESET_PASSWORD_AGAIN")}</a>
      </>
    </PageNotFound>
  );
};

export default LinkExpiredPage;

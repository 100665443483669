import Loadable from "react-loadable";
import Loading from "@/components/loading/loading";
import FullScreenloading from "@/components/loading/fullScreenLoading";

const LoadableHome = Loadable({
  loader: () => import("@/pages/overviewPage/overviewPage"),
  loading: Loading,
});
const LoadableOverview = Loadable({
  loader: () => import("@/pages/overviewPage/overviewPage"),
  loading: FullScreenloading,
});
const LoadableTestReport = Loadable({
  loader: () => import("@/pages/reportPage/test/testReportPage"),
  loading: Loading,
});
const LoadableModal = Loadable({
  loader: () => import("react-bootstrap/Modal"),
  loading: Loading,
});
const LoadableLogin = Loadable({
  loader: () => import("@/pages/loginPage"),
  loading: FullScreenloading,
});
const LoadableCorporateList = Loadable({
  loader: () => import("@/pages/corperateListPage/corporateListPage"),
  loading: FullScreenloading,
});
const LoadableManagement = Loadable({
  loader: () => import("@/pages/managementPage/managementPage"),
  loading: Loading,
});
const LoadableCounselling = Loadable({
  loader: () => import("@/pages/counsellingComponent/counsellingComponent"),
  loading: Loading,
});
const LoadbleForgotPassword = Loadable({
  loader: () => import("@/pages/forgotPassword/forgotPassword"),
  loading: Loading,
});
const LoadbleResetPassword = Loadable({
  loader: () => import("@/pages/forgotPassword/resetPassword"),
  loading: Loading,
});
const LoadableCounseling = Loadable({
  loader: () => import("@/pages/reportPage/counseling/counselingReportPage"),
  loading: Loading,
});
const LoadableSetting = Loadable({
  loader: () => import("@/pages/settingPage/settingPage"),
  loading: Loading,
});
const LoadblePageNotFound = Loadable({
  loader: () => import("@/pages/errorPage/pageNotFound"),
  loading: Loading,
});
const LoadableErrorPage = Loadable({
  loader: () => import("@/pages/errorPage/errorPage"),
  loading: Loading,
});

export const Home = LoadableHome;
export const Overview = LoadableOverview;
export const ModalDialog = LoadableModal;
export const TestReport = LoadableTestReport;
export const Counseling = LoadableCounseling;
export const Login = LoadableLogin;
export const CorporateList = LoadableCorporateList;
export const Management = LoadableManagement;
export const CounsellingComponent = LoadableCounselling;
export const ForgotPassword = LoadbleForgotPassword;
export const ResetPassword = LoadbleResetPassword;
export const Setting = LoadableSetting;
export const PageNotFound = LoadblePageNotFound;
export const ErrorPage = LoadableErrorPage;

import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { Col, Row } from "react-bootstrap";

import "@/components/confirmModal/confirmModal.scss";
import { useTranslation } from "react-i18next";

export interface ConfirmModalProps {
  isShow: boolean;
  setIsShow: () => void;
  isConfirm: boolean;
  isClose?: boolean;
  confirmText?: string;
  cancelText?: string;
  handleConfirm?: () => void;
  setHide?: () => void;
  title?: string;
  children?: React.ReactNode;
  icon?: JSX.Element | undefined;
  size?: "sm" | "lg" | "xl";
  closeIcon?: boolean;
  confirmDisabled?: boolean;
  confirmVariant?: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  const { t } = useTranslation();
  const { isClose = true, confirmDisabled = false } = props;

  return (
    <>
      <Modal
        className="confirm-modal"
        show={props.isShow}
        onHide={props.setHide}
        size={props.size}
      >
        {props.closeIcon && (
          <Modal.Header
            className="mb-0"
            closeButton={props.closeIcon}
          ></Modal.Header>
        )}
        <Modal.Body
          className={`text-center ${props.closeIcon ? "m-0 p-0" : ""}`}
        >
          {props.icon}
          <div className="my-2 mt-4">
            <h1 className="text-title1">{props.title}</h1>
            <div className="text-subheader-1">{props.children}</div>
          </div>

          <Row className="mt-3">
            {props.isConfirm && (
              <Col md={12} className="d-flex justify-content-center">
                <Button
                  className="w-75 py-2"
                  variant={
                    props.confirmVariant ? props.confirmVariant : "primary"
                  }
                  onClick={props.handleConfirm}
                  disabled={confirmDisabled}
                >
                  {props.confirmText ? props.confirmText : t("SAVE_CHANGES")}
                </Button>
              </Col>
            )}

            {isClose && (
              <Col md={12}>
                <button
                  className="mt-2 w-75 py-2 close-button"
                  onClick={props.setHide}
                >
                  {props.cancelText ? props.cancelText : t("CLOSE")}
                </button>
              </Col>
            )}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmModal;

import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "@/components/modalSendEmail/modalSendEmail.scss";
export interface ModalSendEmailProps {
  isShow: boolean;
  setIsShow: () => void;
  isConfirm: boolean;
  isClose?: boolean;
  confirmText?: string;
  cancelText?: string;
  handleConfirm?: () => void;
  setHide?: () => void;
  title?: string;
  children?: React.ReactNode;
  icon?: JSX.Element | undefined;
  size?: "sm" | "lg" | "xl";
  closeIcon?: boolean;
  confirmDisabled?: boolean;
}

const ModalSendEmail: React.FC<ModalSendEmailProps> = (props) => {
  const { t } = useTranslation();
  const { isClose = true, confirmDisabled = false } = props;
  return (
    <>
      <Modal
        className="confirm-modal"
        show={props.isShow}
        onHide={props.setHide}
        size={props.size}
      >
        {props.closeIcon && (
          <Modal.Header
            className="mb-0"
            closeButton={props.closeIcon}
          ></Modal.Header>
        )}
        <Modal.Body
          className={`text-center ${props.closeIcon ? "m-0 p-0" : ""}`}
        >
          {props.icon}
          <div className="my-2 mt-4">
            <h1 className="title-container text-h3 text-black py-2">
              {props.title}
            </h1>
            <div className="text-body1">{props.children}</div>
          </div>

          <Row>
            {props.isConfirm && (
              <Col md={12} className="d-flex justify-content-center">
                <Button
                  type="button"
                  className="w-75 py-2 text-button-small"
                  variant="primary"
                  onClick={props.handleConfirm}
                  disabled={confirmDisabled}
                >
                  {props.confirmText
                    ? props.confirmText
                    : t("MANAGEMENT_BUTTON_SEND_INVITATION")}
                </Button>
              </Col>
            )}

            {isClose && (
              <Col md={12}>
                <button
                  className="back-button text-button-small mt-2 w-75 py-2 "
                  onClick={props.setHide}
                >
                  {props.cancelText ? props.cancelText : t("BACK")}
                </button>
              </Col>
            )}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalSendEmail;

import "@/components/loading/loader.scss";
import { FC } from "react";
import "@/components/cardLayout/cardLayout.scss";

export interface LoaderProps {
  show: boolean;
  title?: string;
  bodyText?: string;
}

const Loader: FC<LoaderProps> = ({ show, title, bodyText }) => {
  return (
    <div className={show ? "" : "d-none"}>
      <div className="container-loading  m-auto">
        <div className="background"></div>
        <div className="container-card">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <h1 className="sr-only my-3">{title}</h1>
          <div className="text-body1">{bodyText}</div>
        </div>
      </div>
    </div>
  );
};

export default Loader;

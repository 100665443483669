import {
  Routes as Switch,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Navbar from "@/components/navbar/navbar";
import { PATH } from "@/constants/path";
import { useTranslation } from "react-i18next";
import {
  Home,
  TestReport,
  Login,
  CorporateList,
  Management,
  Counseling,
  ResetPassword,
  Setting,
  ErrorPage,
} from "@/loadable";
import { useEffect, useState } from "react";
import Sidebar, { SidebarProps } from "@/components/sidebar/sidebar";
import ProtectedRoute from "@/routes/protectedRoute";
import { getUserToken } from "@/utils/authUtil";
import { Role } from "@/constants/role";
import ForgotPassword from "@/pages/forgotPassword/forgotPassword";
import PageNotFound from "@/pages/errorPage/pageNotFound";
import { clientAdmin } from "./utils/axios";
import LinkExpiredPage from "./pages/errorPage/linkExpired";

const Routes = () => {
  const { t } = useTranslation();
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const APP_VERSION = "1.0.0";
  const user = getUserToken();
  const [defaultorganization, setDefaultOrganization] = useState<string>("");
  const location = useLocation();

  let organizationId: number;

  if (user && user.data && user.data.meta && user.data.meta.organization_id) {
    organizationId = user?.data.meta.organization_id;
  } else if (
    user &&
    user.data &&
    user.data.employees &&
    user.data.employees.data[0] &&
    user.data.employees.data[0].organization.data.id
  ) {
    organizationId = user?.data.employees.data[0].organization.data.id;
  }

  useEffect(() => {
    if (location.pathname === PATH.OVERVIEW) {
      clientAdmin(true)
        .get(`/organizations/${organizationId}`)
        .then((res) => {
          setDefaultOrganization(res.data.data.name);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const toggleSidebar = function (): void {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  const sidebarProps: SidebarProps = {
    appVersion: APP_VERSION,
    isSidebarCollapsed: isSidebarCollapsed,
    toggleSidebar: toggleSidebar,
  };

  const sidebar = (page: React.ReactNode | null) => {
    return (
      <>
        <Sidebar {...sidebarProps}></Sidebar>
        <div className="app-container">{page}</div>
      </>
    );
  };

  return (
    <>
      <Switch>
        {/* SUPER ADMIN ROUTE */}
        <Route
          element={
            <ProtectedRoute
              roleCheck={[Role.SUPERADMIN]}
              redirectPath="/error-page"
            />
          }
        >
          <Route
            key={PATH.CORPORATE_LIST}
            path={PATH.CORPORATE_LIST}
            element={<CorporateList />}
          />
          <Route
            key={PATH.SETTING}
            path={PATH.SETTING}
            element={sidebar(pageTitle(<Setting />, t("PAGE_SETTING")))}
          />
        </Route>

        {/* PRIVATE ROUTE */}
        <Route
          element={
            <ProtectedRoute
              roleCheck={[
                Role.ORGANIZATION_ADMIN,
                Role.ORGANIZATION_SUPER_ADMIN,
                Role.SUPERADMIN,
                Role.ADMIN,
              ]}
            />
          }
        >
          <Route path="/" element={<Navigate to={PATH.OVERVIEW} />} />
          <Route
            key={PATH.OVERVIEW}
            path={PATH.OVERVIEW}
            element={sidebar(
              pageTitle(<Home />, "Welcome " + defaultorganization)
            )}
          />
          <Route
            key={PATH.MANAGEMENT}
            path={PATH.MANAGEMENT}
            element={sidebar(pageTitle(<Management />, t("PAGE_MANAGEMENT")))}
          />
          <Route
            key={PATH.TEST}
            path={PATH.TEST}
            element={sidebar(pageTitle(<TestReport />, t("PAGE_TEST_REPORT")))}
          />
          <Route
            key={PATH.COUNSELING}
            path={PATH.COUNSELING}
            element={sidebar(pageTitle(<Counseling />, t("PAGE_COUNSELING")))}
          />
        </Route>

        {/* PUBLIC ROUTE */}
        <Route key={PATH.LOGIN} path={PATH.LOGIN} element={<Login />} />
        <Route
          key={PATH.FORGOT_PASSWORD}
          path={PATH.FORGOT_PASSWORD}
          element={<ForgotPassword />}
        />
        <Route
          key={PATH.RESET_PASSWORD}
          path={PATH.RESET_PASSWORD}
          element={<ResetPassword />}
        />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/error-page" element={<ErrorPage />} />
        <Route path="/link-expired" element={<LinkExpiredPage />} />
      </Switch>
    </>
  );
};

const pageTitle = (page: React.ReactNode | null, pageTitle?: string) => {
  const user = getUserToken();

  //TODO: manage case no auth
  return (
    <>
      <Navbar
        userName={user?.data.email ?? ""}
        pageName={pageTitle ?? ""}
      ></Navbar>
      {page}
    </>
  );
};

export default Routes;

export enum PATH {
  OVERVIEW = "/home",
  COUNSELING = "/counseling",
  COUNSELING_COMPONENT = "/counselling-components",
  TEST = "/test",
  PEOPLE = "/people",
  MANAGEMENT = "/management",
  SETTING = "/setting",
  CORPORATE_LIST = "/corporate-list",
  LOGIN = "/login",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password/",
  ERROR_PAGE = "/error-page",
  LINK_EXPIRED_PAGE = "/link-expired",
}

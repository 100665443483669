import { useState } from "react";
import { Nav } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ISidebarItem, SIDEBAR_ITEMLIST } from "@/constants/sidebarItem";
import controlBackSmall from "@/assets/svg/control-back-small.svg";
import controlNextSmall from "@/assets/svg/control-next-small.svg";
import { PATH } from "@/constants/path";
import oocaLogo from "@/assets/svg/ooca_logo-default-side.svg";
import logOutIcon from "@/assets/svg/logout-regular.svg";
import sadMooca from "@/assets/svg/sad-mooca-size-m.svg";
import ItemLink from "@/components/sidebar/item/item";
import ModalSendEmail from "@/components/modalSendEmail/modalSendEmail";

import "@/components/sidebar/sidebar.scss";
import { getUserToken } from "@/utils/authUtil";
import { UserToken } from "@/Interfaces/userToken";
import { Role } from "@/constants/role";

export interface SidebarProps {
  appVersion: string;
  isSidebarCollapsed: boolean;
  toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
  let sidebarItems: ISidebarItem[] = SIDEBAR_ITEMLIST;
  const { t } = useTranslation();
  const user = getUserToken() as UserToken;
  const { roles } = user.data;
  const navigate = useNavigate();
  const [isShowLogoutModal, setisShowLogoutModal] = useState<boolean>(false);

  const toggleShowLogout = (): void => {
    setisShowLogoutModal(!isShowLogoutModal);
  };

  const handleComfirmLogout = async (): Promise<void> => {
    await localStorage.removeItem("token");
    await localStorage.removeItem("refresh_token");
    await localStorage.removeItem("firebase_auth_token");
    setisShowLogoutModal(false);
    navigate(PATH.LOGIN);
  };

  const renderSidebarItems = (): JSX.Element[] => {
    if (!roles.includes(Role.SUPERADMIN)) {
      sidebarItems = sidebarItems.filter((item) => {
        return item.path !== PATH.CORPORATE_LIST && item.path !== PATH.SETTING;
      });
    }

    return sidebarItems.map((item, index) => {
      return <ItemLink key={index} {...item} />;
    });
  };

  return (
    <>
      <div className={"sidebar" + (props.isSidebarCollapsed ? " collapsed" : "")}>
        <div className="logo">
          <SVG src={oocaLogo} />
        </div>
        <div className="item-container">
          <div className="top">
            <Nav activeKey="/home">{renderSidebarItems()}</Nav>
          </div>

          <div className="bottom">
            <Nav>
              <Nav.Item key="logout-key" className="nav-main-item">
                <Nav.Link eventKey="logout-key" className={"sidebar-content"} onClick={() => {}}>
                  <SVG className="item-svg" src={logOutIcon} />
                  <span className="text-subheader1" onClick={() => setisShowLogoutModal(true)}>
                    {t("MENU_LOGOUT")}
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <button className="sidebar-toggle-button" onClick={() => props.toggleSidebar()}>
          <SVG src={props.isSidebarCollapsed ? controlNextSmall : controlBackSmall} />
        </button>
      </div>
      <ModalSendEmail
        isConfirm
        confirmText={t("MENU_LOGOUT")}
        cancelText={t("NEVER_MIND")}
        isShow={isShowLogoutModal}
        setIsShow={toggleShowLogout}
        setHide={toggleShowLogout}
        icon={<SVG src={sadMooca}></SVG>}
        handleConfirm={handleComfirmLogout}
      >
        <h3 className="text-h3 text-black mb-5">{t("LOGOUT_ARE_YOU_SURE")}</h3>
      </ModalSendEmail>
    </>
  );
};

export default Sidebar;

import EmailValidationForm from "@/components/forgotPassword/emailValidationForm";
import OtpValidationForm from "@/components/forgotPassword/otpValidationForm";
import { useState } from "react";

const ForgetPassword = () => {
  const [showEmailForm, setShowEmailForm] = useState<boolean>(true);
  const [showOtpForm, setShowOtpForm] = useState<boolean>(false);
  const [emailValue, setEmailValue] = useState<string>("");

  const handleEmailShow = (isShow: boolean, email: string): void => {
    setShowEmailForm(isShow);
    setShowOtpForm(true);
    setEmailValue(email);
  };

  return (
    <>
      {showEmailForm && <EmailValidationForm isShow={showEmailForm} setIsShow={handleEmailShow} />}
      {showOtpForm && <OtpValidationForm isShow={showOtpForm} email={emailValue} />}
    </>
  );
};

export default ForgetPassword;

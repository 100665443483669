import { UserToken } from "@/Interfaces/userToken";
import jwt_decode from "jwt-decode";

export const getUserToken = () => {
  const token = localStorage.getItem("token") ?? null;
  if (token) {
    const user = jwt_decode(token) as UserToken;

    return user;
  }
  return null;
};

export const saveUserToken = (token: string) => {
  localStorage.setItem("token", token);
};

export const saveRefreshToken = (refreshToken: string) => {
  localStorage.setItem("refresh_token", refreshToken);
};

export const removeToken = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh-token");
};

export const isJsonString = (stringValue: string) => {
  try {
    JSON.parse(stringValue);
  } catch (e) {
    return false;
  }
  return true;
};

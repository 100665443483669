/* eslint-disable jsx-a11y/anchor-is-valid */
import { OocaCard } from "@/components/oocaCard/oocaCard";
import { Button, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { clientAuth } from "@/utils/axios";
import { useState } from "react";
import { toast } from "react-toastify";
import ConfirmModal from "@/components/confirmModal/confirmModal";
import SVG from "react-inlinesvg";
import moocaIcon from "@/assets/svg/mooca-logo.svg";
import "@/components/login/loginForm.scss";
import "@/pages/forgotPassword/forgotPassword.modules.scss";

interface FormInterface {
  email: string;
  otp: string;
}

interface EmailValidationProps {
  isShow: boolean;
  setIsShow: (isShow: boolean, email: string) => void;
}

const EmailValidationForm: React.FC<EmailValidationProps> = (props) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string>("");
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailText, setEmailText] = useState<string>("");

  let schema = yup.object().shape({
    email: yup.string().email().required(),
  });

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormInterface>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormInterface) => {
    setIsLoading(true);
    const { email } = await getValues();

    const body = JSON.stringify({
      email: email,
      url: `${process.env.REACT_APP_OOCA_BUSINESS}/reset-password`,
    });

    clientAuth()
      .post("/authen/email-links", body)
      .then((res) => {
        setIsShowModal(true);
        setEmailText(email);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        if (err.response.status === 500) {
          setError(err.response.data.message);
        } else {
          toast.error(error.toString());
        }
      });
  };

  const toggleShow = () => {
    setIsShowModal(!isShowModal);
  };

  const onSetHide = async () => {
    const { email } = await getValues();

    setIsShowModal(!isShowModal);
    props.setIsShow(false, email);
  };

  return (
    <>
      {props.isShow && (
        <>
          <div className="page-center">
            <OocaCard
              title={t("LOGIN_FORGOT_PASSWORD")}
              subTitle={t("LOGIN_FORGOT_PASSWORD_SUBTITLE")}
            >
              <Form className="input-login-form mt-4" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="px-2 group-email">
                  <Form.Label className="text-body2 mb-2 text-blue-gray-700 ">
                    {t("LOGIN_EMAIL")}
                  </Form.Label>
                  <Form.Control
                    className="text-body3 ooca-text-box "
                    type="email"
                    placeholder="Email"
                    {...register("email", { required: true })}
                    isInvalid={!!errors.email}
                  />

                  {error !== "" ? (
                    <Form.Label className="text-body5 justify-content-center error-password-message">
                      {error}
                    </Form.Label>
                  ) : (
                    <Form.Control.Feedback type="invalid">
                      {t("LOGIN_FORM_EMAIL_ERROR")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Button
                  className="btn-login text-button-small"
                  type="submit"
                  variant="primary"
                  disabled={isLoading}
                >
                  {t("SUBMIT")}
                </Button>
              </Form>
            </OocaCard>
          </div>

          <ConfirmModal
            isConfirm={false}
            isShow={isShowModal}
            setIsShow={toggleShow}
            setHide={onSetHide}
            title={"Verify email"}
            icon={<SVG src={moocaIcon}></SVG>}
          >
            <>
              <Row>
                <div>
                  {t("SENT_EMAIL_TEXT")}{" "}
                  <span className="text-blue-gray-1 fw-bold">{emailText}.&nbsp;</span>
                </div>
              </Row>

              <Row>
                <div>{t("ACCESS_THE_LINK_IN_EMAIL")}</div>
              </Row>
            </>
          </ConfirmModal>
        </>
      )}
    </>
  );
};

export default EmailValidationForm;
